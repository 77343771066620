<template>
  <div :class="['c-recent-bets', wrapperClasses]">
    <div class="c-recent-bets__intro">
      <h4 class="c-recent-bets__title">
        {{ $t("general.recent_bets") }} 💸
      </h4>
    </div>
    <div class="c-recent-bets__actions">
      <div class="c-recent-bets__actions-buttons">
        <button
          aria-label="filter-all"
          class="c-recent-bets__btn"
          :class="{ 'c-recent-bets__btn-selected': betsCategory == 0 }"
          @click="selectBets(0)"
        >
          {{ $t("general.all") }}
        </button>
        <button
          aria-label="filter-big-wins"
          class="c-recent-bets__btn"
          :class="{ 'c-recent-bets__btn-selected': betsCategory == 1 }"
          @click="selectBets(1)"
        >
          {{ $t("general.big_wins") }}
        </button>
        <button
          aria-label="filter-my-bets"
          class="c-recent-bets__btn"
          :class="{ 'c-recent-bets__btn-selected': betsCategory == 2 }"
          @click="selectBets(2)"
        >
          {{ $t("general.my_bets") }}
        </button>
      </div>
    </div>
    <div class="c-recent-bets__head">
      <div class="c-recent-bets__row">
        <div class="c-recent-bets__row-item is-for-bettor">
          {{ $t("general.user") }}
        </div>
        <div class="c-recent-bets__row-item is-for-game">
          {{ $t("general.game") }}
        </div>
        <div
          v-if="showAllCollumns"
          class="c-recent-bets__row-item is-for-date"
        >
          {{ $t("general.date") }}
        </div>

        <div
          v-if="showAllCollumns"
          class="c-recent-bets__row-item is-for-bet"
        >
          {{ $t("general.bet") }}
        </div>
        <div class="c-recent-bets__row-item is-for-payout">
          {{ $t("general.payout") }}
          (Chips)
        </div>
        <!--<div class="c-recent-bets__row-item is-for-details">
          {{ showAllCollumns ? $t("general.verify") : "" }}
        </div>-->
      </div>
    </div>
    <div class="c-recent-bets__ctn">
      <transition-group v-if="!isMobile"
                        class="c-recent-bets__body"
                        name="recent-bets"
                        tag="div"
      >
        <div
          v-for="(item) in recentBets"
          :key="item._id"
        >
          <div
            v-if="getIconPath(item.game)"
            :class="['c-recent-bets__row']"
          >
            <img v-if="item.avatar"
                 :src="/\/\d$/.test(item.avatar)?item.avatar+'.png':item.avatar"
                 class="c-recent-bets__row-item-avatar"
                 alt=""
                 onError="this.onerror=null;this.src='https://cdn.discordapp.com/embed/avatars/4.png';"
            />

            <div class="c-recent-bets__row-item is-for-bettor is-primary-bold"
                 :class="{'c-recent-bets__row-item-with-avatar':item.avatar}"
            >
              {{ item.username }}
            </div>
            <div class="c-recent-bets__row-item is-for-game">
              <img
                class="c-recent-bets__row-item-game-icon"
                :src="getIconPath(item.game)"
                alt=""
              />
              <span>
              {{ getGameTitle(item.game) }}
            </span>
            </div>

            <div
              v-if="showAllCollumns"
              class="c-recent-bets__row-item is-for-date"
            >
              {{ dateFormat(item.timestamp) }}
              <br />
              {{ timeFormat(item.timestamp) }}
            </div>

            <div
              v-if="showAllCollumns"
              class="c-recent-bets__row-item is-for-bet"
            >
              {{ $filters.formatNumber( item.bet  ) }}
            </div>

            <div
              class="c-recent-bets__row-item is-for-payout"
              style="display: inline-block;"
              :class="{ 'is-green': item.chipsWon > 0 }"
            >
              <span v-if="item.isFreeSpin" style="color: #f9fa01">
              Freespin
              <br />
            </span>
              <span v-if="item.isBonus" style="color: #f9fa01">
              Bonus
              <br />
            </span>
              {{ $filters.formatNumber( item.chipsWon  ) }}
            </div>
            <!--<div class="c-recent-bets__row-item is-for-details">
              <button
                class="c-btn c-btn--padding-0 c-btn--bg-transparent"
                @click="openVerify(item.transactionId)"
              >
                <img
                  class="c-recent-bets__row-item-icon"
                  src="../../assets/images/icon-info.webp"
                  alt="more-details"
                />
              </button>
            </div>-->
          </div>
        </div>
      </transition-group>
      <div v-else class="c-recent-bets__body">
        <div
          v-for="(item) in recentBets"
          :key="item._id"
        >
          <div
            v-if="getIconPath(item.game)"
            :class="['c-recent-bets__row']"
          >
            <img v-if="item.avatar"
                 :src="/\/\d$/.test(item.avatar)?item.avatar+'.png':item.avatar"
                 class="c-recent-bets__row-item-avatar"
                 alt=""
                 onError="this.onerror=null;this.src='https://cdn.discordapp.com/embed/avatars/4.png';"
            />

            <div class="c-recent-bets__row-item is-for-bettor is-primary-bold"
                 :class="{'c-recent-bets__row-item-with-avatar':item.avatar}"
            >
              {{ item.username }}
            </div>
            <div class="c-recent-bets__row-item is-for-game">
              <img
                class="c-recent-bets__row-item-game-icon"
                :src="getIconPath(item.game)"
                alt=""
              />
              <span>
              {{ getGameTitle(item.game) }}
            </span>
            </div>

            <div
              v-if="showAllCollumns"
              class="c-recent-bets__row-item is-for-date"
            >
              {{ dateFormat(item.timestamp) }}
              <br />
              {{ timeFormat(item.timestamp) }}
            </div>

            <div
              v-if="showAllCollumns"
              class="c-recent-bets__row-item is-for-bet"
            >
              {{ $filters.formatNumber( item.bet  ) }}
            </div>

            <div
              class="c-recent-bets__row-item is-for-payout"
              style="display: inline-block;"
              :class="{ 'is-green': item.chipsWon > 0 }"
            >
              <span v-if="item.isFreeSpin" style="color: #f9fa01">
              Freespin
              <br />
            </span>
              <span v-if="item.isBonus" style="color: #f9fa01">
              Bonus
              <br />
            </span>
              {{ $filters.formatNumber( item.chipsWon  ) }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { allGames, scratchCards, crashGames, instantGames } from "../../constants/index";

export default {
  name: "RecentBetstable",
  props: {
    wrapperClasses: {
      type: String,
      required: false,
      default: "",
    },
    showAllCollumns: {
      type: Boolean,
      required: false,
      default: false,
      isUpdating:false,
    },
  },
  data() {
    return {
      betsCategory: 0,
      recentBetsTable:new Map(),
      recentBets:[],
      type:this.$store.getters.getSelectedGamesType,
      isMobile : window.innerWidth <= 900,
    };
  },
  computed: {
    games(){
      return this.$store.getters.getSelectedGamesType==="slot"?allGames:
        this.$store.getters.getSelectedGamesType==="scratch"?scratchCards:
        this.$store.getters.getSelectedGamesType==="crash"?crashGames:
        this.$store.getters.getSelectedGamesType==="instant"?instantGames:null;
    },
    data() {
      let gamesType =this.$store.getters.getSelectedGamesType;
      let bets = this.$store.getters.getBets;
      if (!bets || !bets.all) {
        return null;
      }
      switch (this.betsCategory) {
        case 0:
          return bets.all;
        case 1:
          return bets.big;
        case 2:
          return bets.mine;
        default:
          return null;
      }
    },
  },
  watch:{
    betsCategory(){
      this.recentBetsTable.clear();
      for (const bet of this.data) {
        this.recentBetsTable.set(bet._id, bet);
      }
      this.recentBets=this.data;
    },
    async data(newData) {
      if(this.$store.getters.getSelectedGamesType!== this.type){
        this.type=this.$store.getters.getSelectedGamesType;
        this.recentBets=[];
      }
      if(this.recentBets.length===0) {
        this.recentBets = this.data;
        for (const bet of this.data) {
          this.recentBetsTable.set(bet._id, bet);
        }
      }
      if(!this.isUpdating) {
        this.isUpdating=true;
        let cat = this.betsCategory;
        let numNewBets = 0;
        for (const bet of newData) {
          if (!this.recentBetsTable.has(bet._id)) {
            numNewBets++;
          }
        }
        if(numNewBets > 0) {
          let newBetInsertTimes = [];
          for(let i=0; i<numNewBets; i++) {
            newBetInsertTimes.push(Math.floor(Math.random()*this.$store.getters.getRecentBetsUpdateTime*1000));
          }
          newBetInsertTimes.sort(function(a, b) {
            return a - b;
          });
          let newBetIndex = 0;

          let newDataReverse = [...newData].reverse();
          for (const bet of newDataReverse) {
            if( this.betsCategory !== cat) {
              this.isUpdating=false;
              return;
            }
            if (!this.recentBetsTable.has(bet._id)) {
              if (this.recentBetsTable.size >= this.data.length) {
                let oldBet= this.recentBets.pop();
                this.recentBetsTable.delete(oldBet);
              }
              this.recentBets.unshift(bet)
              this.recentBetsTable.set(bet._id, bet);
              let sleepTime = newBetInsertTimes[newBetIndex];
              if(newBetIndex > 0) {
                sleepTime -= newBetInsertTimes[newBetIndex-1];
              }
              await this.sleep(sleepTime);
              newBetIndex++;
            }
          }
        }
        this.isUpdating=false;
      }
    }
  },
  mounted() {
    if(location.pathname.includes('game')){
      this.$store.commit("setSelectedGamesType","slot");
      this.type = 'slot';
    }else if(location.pathname.includes('scratch')){
      this.$store.commit("setSelectedGamesType","scratch");
      this.type = 'scratch';
    }
    window.addEventListener('resize', () => {
      this.isMobile = window.innerWidth <= 900;
    });
  },
  methods: {
    sleep(ms) {
      return new Promise(resolve => setTimeout(resolve, ms));
    },
    getIconPath(gameName) {
      return gameName && this.games[gameName]
        ? require(`../../assets/images/${gameName}-ant.webp`)
        : "";
    },
    getGameTitle(gameName) {
      return gameName && this.games[gameName] ? this.games[gameName].title : "";
    },
    selectBets(category) {
      this.betsCategory = category;
    },
    dateFormat(timestamp) {
      let date = new Date(timestamp);
      return date.toLocaleString("en-GB", {
        month: "short",
        day: "2-digit",
      });
    },
    timeFormat(timestamp) {
      let date = new Date(timestamp);
      return date.toLocaleString("en-GB", {
        hour: "2-digit",
        minute: "2-digit",
        second: "2-digit",
      });
    }
  },
};
</script>

<style lang="scss" scoped>
@import "../../assets/styles/components/recent-bets-table";
</style>
