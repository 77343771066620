<template>
  <article :class="['c-card', wrapperClasses]">
    <h2 class="c-card__title">
      <span
          style="
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
        "
      >{{ title }}</span>
      <svg
          v-if="isLocked"
          class="c-card__title-lock"
          viewBox="0 0 512 512"
          style="enable-background:new 0 0 512 512;"
      >
        <g id="XMLID_1_">
          <path id="XMLID_3_" fill="#F1C40F" d="M99.9,177.7c-24.4,0-44.8,19.7-44.8,44.8v22v133.7v22c0,24.4,19.7,44.8,44.8,44.8h44.8h222.6   h44.8c24.4,0,44.8-19.7,44.8-44.8V244.6v-22c0-24.4-19.7-44.8-44.8-44.8h-44.8H99.9z" />
          <path id="XMLID_4_" fill="#F39C12" d="M99.9,244.6c-24.4,0-44.8,19.7-44.8,44.8v22v133.7v22c0,24.4,19.7,44.8,44.8,44.8h44.8h222.6   h44.8c24.4,0,44.8-19.7,44.8-44.8V311.4v-22c0-24.4-19.7-44.8-44.8-44.8h-44.8H99.9z" />
          <path id="XMLID_5_" fill="#BDC3C7" d="M255.6,0C169.9,0,99.9,69.2,99.9,155.7h66.9c0-48.8,40.1-88.9,88.9-88.9s88.9,40.1,88.9,88.9   h66.9C411.3,69.2,341.3,0,255.6,0z" />
          <path id="XMLID_10_" fill="#E67E22" d="M99.9,288.6v22h311.4v-22H99.9z M99.9,333.5v22h311.4v-22H99.9z M99.9,377.5v22h311.4v-22   C411.3,377.5,99.9,377.5,99.9,377.5z M99.9,422.3v22h311.4v-22H99.9z" />
          <path id="XMLID_11_" fill="#7F8C8D" d="M99.9,177.7v22c0,11,14.9,22,33,22c18.1,0,33-11,33-22v-22c0,11-14.9,22-33,22   S99.9,188.8,99.9,177.7z" />
          <path id="XMLID_12_" fill="#7F8C8D" d="M344.5,177.7v22c0,11,14.9,22,33,22c18.1,0,33-11,33-22v-22c0,11-14.9,22-33,22   C359.4,199.8,344.5,188.8,344.5,177.7z" />
          <path id="XMLID_13_" fill="#95A5A6" d="M255.6,32.2c-7.9,0-15.7,0.8-22.8,2.4c-5.5,0.8-11,2.4-15.7,3.9c-4.7,1.6-9.4,3.1-14.2,5.5   c-2.4,0.8-4.7,2.4-7.1,3.1c-3.1,1.6-5.5,3.9-8.7,5.5c-2.4,1.6-5.5,3.1-7.9,4.7c-3.1,2.4-6.3,4.7-8.7,7.9l-1.6,3.1   c-3.9,3.9-7.1,7.1-10.2,11.8c-3.1,4.7-6.3,8.7-8.7,14.2v0.8c-2.4,5.5-4.7,11-6.3,16.5c-5.5,13.4-21.2,22-38.5,22h-3.1h-0.8l-1.6,22   v22v22h66.9v-22v-22c0-6.3,0.8-12.6,2.4-18.1c8.7-40.9,44-70.8,86.5-70.8c48.8,0,88.9,40.1,88.9,88.9v22v22h66.9v-22v-22l-1.6-22   H409h-3.1c-18.1,0-33-9.4-38.5-22c-1.6-5.5-3.9-11.8-6.3-16.5v-0.8c-2.4-4.7-5.5-9.4-8.7-14.2c-3.1-4.7-6.3-7.9-10.2-11.8l-2.4-3.1   c-3.1-2.4-5.5-5.5-8.7-7.9c-2.4-1.6-5.5-3.1-7.9-4.7s-5.5-3.9-8.7-5.5c-2.4-1.6-4.7-2.4-7.1-3.1c-4.7-2.4-9.4-3.9-14.2-5.5   c-5.5-1.6-10.2-3.1-15.7-3.9C271.3,33,263.5,32.2,255.6,32.2z" />
        </g>
      </svg>
    </h2>
    <div class="flex-column c-inner-card"
         style="
    border-radius: 20px;
    overflow: hidden;"
    >
      <div v-if="page!=='spincity'"
           class="c-card__desc"
           :class="{ 'c-card__desc-scratch': isScratch }"
           :style="page!=='scratch' && (isSoon||isNewGame)?'padding:35px 15px;':page==='scratch' && (isSoon||isNewGame)?'padding:7px 0;':''"
      >
        <transition name="slide-fade-dropdown">
          <div v-if="showRatioInfo"
               class="info-bubble-popup"
               :style="isNewGame&&page!=='scratch'?'top:60px':''"
          >
            Number of free gems per chips played, {{miningRatio*10}}-10 means {{miningRatio*10}} gem(s) per 10 chips played.
          </div>
        </transition>
        <p v-if="miningRatio!==undefined"
           class="mining-ratio"
           @mouseenter="showRatioInfo=true;"
           @mouseleave="showRatioInfo=false;"
        >Mining Ratio {{miningRatio*10}}-10</p>
        <p class="game-details">
          {{ (!cost||!isMobile?description:'')+(cost?"\nCost: "+cost:"")+(maxwin?"\nMax Win: "+maxwin.toString().replace(/[0-9](?=(?:[0-9]{3})+(?![0-9]))/g, "$&,"):"") }}
        </p>

      </div>
      <div class="c-card__img-wrap">
        <img class="c-card__img"
             :style=" page==='crash'|| page==='spincity'?'border-radius: 0%;':
               isHome?'border-radius: 9%;':''"

             :src="getIconPath(imgPath)"
             :alt="title"
        />
      </div>
      <div
          v-if="isSoon"
          class="new-game-button"
          :style="page==='crash'?'width:20%; background: rgb(24 176 255); padding: 3px 7px; color:white;':'background: rgb(24 176 255); padding: 3px 7px; color:white;'"
      >SOON</div>
      <div
          v-else-if="isNewGame"
          class="new-game-button"
          :style="page==='crash'?'width:20%':''"
      >
        NEW
      </div>

      <div v-if="isCascading" class="cascading">c</div>
      <div class="c-card__btn-wrap">
        <button v-if="!isLocked" class="c-card__btn" :style="isScratch?'width: 25% !important;':'width: 30%'">
          <img :src="require('../../assets/svg-icons/Play.svg')" style="height: 100%"  alt=""/>
        </button>
        <svg
            v-if="isLocked"
            class="c-card__btn"
            viewBox="0 0 512 512"
            style="enable-background:new 0 0 512 512;">
          <g id="XMLID_1_">
            <path id="XMLID_6_" fill="white" d="M384.8,230.8V128.4C384.8,57.5,327.3,0,256.4,0S128,57.5,128,128.4v102.4   c-28.4,0-51.2,22.8-51.2,51.2v178.8c0,28.4,22.8,51.2,51.2,51.2h256c28.4,0,51.2-22.8,51.2-51.2V282   C436,253.6,412.3,230.8,384.8,230.8z M256.4,409.6c-21.3,0-38.6-17.3-38.6-38.6c0-21.3,17.3-38.6,38.6-38.6S295,349.7,295,371   C295,392.3,277.7,409.6,256.4,409.6z M333.6,230.8H180V128.4c0-42.5,34.7-77.2,77.2-77.2s77.2,34.7,77.2,77.2v102.4H333.6z"/>
          </g>
        </svg>
        <p class="c-card__call">
          {{ isLocked?(isSoon?"Soon":$t("general.unlock")) :$t("general.play_now") }}
        </p>
      </div>
    </div>
  </article>
</template>

<script>

export default {
  name: "AppCard",
  components: {
  },
  props: {
    game: {
      type: String,
      require: true,
      default: "",
    },
    title: {
      type: String,
      require: false,
      default: "",
    },
    description: {
      type: String,
      require: false,
      default: "",
    },
    imgPath: {
      type: String,
      require: false,
      default: "",
    },
    wrapperClasses: {
      type: String,
      required: false,
      default: "",
    },
    isNewGame: {
      type: Boolean,
      required: false,
      default: false,
    },
    isCascading: {
      type: Boolean,
      required: false,
      default: false,
    },
    isSoon: {
      type: Boolean,
      required: false,
      default: false,
    },
    unlockPrice: {
      type: Number,
      required: false,
      default: 10000,
    },
    cost: {
      type: Number,
      required: false,
      default: null,
    },
    maxwin: {
      type: Number,
      required: false,
      default: null,
    },
    isLocked: {
      type: Boolean,
      required: true,
      default: false,
    },
    page:{
      type: String,
      require: false,
      default: "slot",}
  },
  data() {
    return {
      playBtnVisible: false,
      isAnimStarted: false,
      isMobile:false,
      showRatioInfo: false,
    };
  },
  computed:{
    isScratch(){
      return this.page==='scratch';
    },
    isHome(){
      return location.pathname.includes('home');
    },
    miningRatio(){
      let game = this.game
      let gamesGemMiningRates = this.$store.getters.getGamesGemMiningRate
      MyLog(gamesGemMiningRates)
      MyLog(game)
      MyLog(gamesGemMiningRates[game])
      return gamesGemMiningRates[game]
    }
  },
  mounted() {
    this.isMobile = window.innerWidth <= 900

    window.addEventListener('resize', () => {
      this.isMobile = window.innerWidth <= 900
    });
  },
  methods: {
    getIconPath(iconName) {
      return iconName ? require(`../../assets/images/${iconName}`) : "";
    },
  },
};
</script>

<style lang="scss" scoped>

.info-bubble-popup {
  width: 100%;
  height: min-content;
  z-index: 1;
  top: 40px;
  left: 0;
}
.mining-ratio{
  background: linear-gradient(91.95deg, #FCC031 -9.33%, #FF2E2F 92.67%);
  border-radius: 50px;
  width: fit-content;
  padding: 0px 10px;
  font-weight: 600;
  font-size: 12px;
  line-height: 200%;
  letter-spacing: -0.03em;
  text-align: center;
  margin-bottom: 10px;
}
.game-details{
  font-weight: 500;
  font-size: 14px;
  line-height: 130%;
  letter-spacing: -0.03em;
  text-align: center;
}
span{
  font-family: 'Rubik', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 100%;
  /* identical to box height, or 15px */

  text-align: center;
  letter-spacing: -0.03em;
}
.c-card {
  display: flex;
  flex-direction: column;
  border-radius: 15px;
  background-image: linear-gradient(to bottom, #0b1729, #182c4a);
  padding: 10px;
  pointer-events: all;
  cursor: pointer;
  overflow: hidden;

  &.c-card--slot {
    .c-card__title {
      padding-left: 20px;
      font-size: 16px;
      position: relative;

      @include media-max(1400px) {
        font-size: 14px;
      }

      &::before {
        content: '';
        width: 14px;
        height: 14px;
        border-radius: 15px;
        box-shadow: 0 0 15px 0 rgba(245, 57, 131, 0.5);
        background-color: $pink;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        left: 0;
      }
    }
    .c-card__desc {
      display: -webkit-box;
      -webkit-line-clamp: 4;
      -webkit-box-orient: vertical;
      @include media-min($screen-tablet-portrait) {
        height: 65px;
      }
    }
  }

  &.c-card--game {
    flex-direction: column-reverse;
    position: relative;
    padding: 0;
    background: transparent;
    border-radius: 15px;

    &:hover {
      .c-card__img-wrap {
        &::after {
          opacity: 0.85;
        }
      }

      .c-card__desc {
        opacity: 1;
      }

      .c-card__btn-wrap {
        opacity: 1;
      }
    }

    &:active {
      .c-card__btn-wrap {
        transform: scale(1.1);
      }
    }

    .c-card__title {
      margin-bottom: 0;
      text-transform: capitalize;

      @include media-min($screen-tablet-portrait) {
        font-size: 14px;
      }
    }

    .c-card__img-wrap {

      &::after {
        content: '';
        transition: $transition-medium;
        opacity: 0;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        border-radius: 15px;
        -webkit-backdrop-filter: blur(2px);
        backdrop-filter: blur(2px);
        background-image: linear-gradient(95.19deg, #0CFF7C -16.14%, #00AF90 137.96%);

      }
    }

    .c-card__desc {
      opacity: 0;
      transition: $transition-medium;
      z-index: 5;
      padding: 15px 5px;
      font-weight: bold;
      font-family: 'Rubik', sans-serif;
      white-space: pre-wrap;
      color: $white;
      text-align: center;
      @include custom-scrollbar();

    }

    .c-card__call {
      transition: $transition-medium;
      z-index: 5;
      font-weight: normal;
      font-family: 'Rubik', sans-serif;
      white-space: pre-wrap;
      color: $white;
      width: 100%;
      overflow: auto;
      font-size: 18px;
      height: 45%;
      text-align: center;
      @include custom-scrollbar();

      @media only screen and (max-width: 1300px) and (min-width: $screen-tablet-landscape) {

        overflow: visible;
      }
    }
  }
  &.c-card-other-games {
    flex-direction: column-reverse;
    position: relative;
    padding: 0 5px;
    background: transparent;
    border-radius: 15px;

    &:hover {
      .c-card__img-wrap {
        &::after {
          opacity: 0.85;
        }
      }

      .c-card__desc-scratch{
        opacity: 1;
      }

      .c-card__btn-wrap {
        opacity: 1;
      }
    }

    &:active {
      .c-card__btn-wrap {
        transform: scale(1.1);
      }
    }

    .c-card__title {
      text-transform: capitalize;
      color: white;
      font-family: 'Rubik', sans-serif;
      font-weight: 400;

      @include media-max(610px){
        margin-top: 10px;
        flex-direction: column;
      }
    }

    .c-card__title-lock{
      @include media-max(610px){
        height: 25px;
      }
    }


    .c-card__desc-scratch{
      opacity: 0;
      transition: $transition-medium;
      z-index: 5;
      padding: 15px 5px !important;
      font-weight: bold;
      font-family: 'Rubik', sans-serif;
      white-space: pre-wrap;
      color: $white;
      text-align: center;
      @include custom-scrollbar();

      @media only screen and (max-width: 1300px) and (min-width: $screen-tablet-landscape) {
        height: 40% !important;
      }

      @include media-max(900px) {
        padding: 0 5px !important;
      }
    }

    .c-card__img-wrap {
      overflow: visible;
      border-radius: 13%;

      &::after {
        content: '';
        transition: $transition-medium;
        opacity: 0;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        border-radius: 13%;
        -webkit-backdrop-filter: blur(2px);
        backdrop-filter: blur(2px);
        background-image: linear-gradient(95.19deg, #0CFF7C -16.14%, #00AF90 137.96%);

      }
    }

    .c-card__desc {
      opacity: 0;
      transition: $transition-medium;
      z-index: 5;
      padding: 15px;
      font-weight: bold;
      font-family: 'Rubik', sans-serif;
      white-space: pre-wrap;
      color: $white;
      height: 0;
      text-align: center;
      @include custom-scrollbar();

      @media only screen and (max-width: 1300px) and (min-width: $screen-tablet-landscape) {
        padding: 1px;
      }



      @include media-max(955px) {
        height: 42%;
      }

      @include media-max(1348px) {
        font-size: 1.5rem;
      }
      @include media-max(950px) {
        font-size: 1.2rem;
      }
      @include media-max(690px) {
        font-size: 1rem;
      }
    }

    .c-card__call {
      transition: $transition-medium;
      z-index: 5;
      font-weight: normal;
      font-family: 'Rubik', sans-serif;
      white-space: pre-wrap;
      color: $white;
      width: 100%;
      opacity: 0;
      overflow: visible;
      font-size: 18px;
      text-align: center;
      @include custom-scrollbar();

      @media only screen and (max-width: 1300px) and (min-width: $screen-tablet-landscape) {
        padding: 10px;
        font-size: 10px;
        overflow: visible;
      }
    }

    .c-card__call{
      height: 0;
    }

    .c-card__btn{
      width: 50%;
    }
  }
}

.c-inner-card{
  position: relative;

}

.c-card__new-game-img {
  position: absolute;
  left: -1.6%;
  top: -1.8%;
  width: 40%;
  z-index: 1;
}
.new-game-button {
  position: absolute;
  left: 5%;
  top: 5%;
  z-index: 1;
  border-radius: 50px;
  padding: 3px 10px;
  width: 46px;
  height: 20px;
  background: #ffe500;
  font-weight: 600;
  font-size: 12px;
  line-height: 130%;
  letter-spacing: -0.03em;
  text-align: center;
  color: black;
}

.c-card__title {
  font-family: 'Rubik', sans-serif;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #0D4455;
  text-align: center;
  height: 41px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  text-align: center;
  align-items: center;
}

.c-card__desc {
  position: absolute;
  align-items: center;
  display: flex;
  flex-direction: column;

  opacity: 0.5;
  font-weight: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  font-family: 'Rubik', sans-serif;

  height: 53%;
  width: 100%;

  font-size: 2rem;
  white-space: break-spaces;
}

.c-card__call {
  font-size: 12px;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  font-family: 'Rubik', sans-serif;
  font-weight: 400;
  overflow: visible;

}

.c-card__img-wrap {
  position: relative;
}

.c-card__img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  min-width: 80px;
  border-radius: 13.5%;
}

.c-card__btn-wrap {
  position: absolute;
  bottom: 0;
  transition: $transition-medium;
  opacity: 0;
  z-index: 5;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  margin-bottom: 10px;
}

.c-card__btn {
  border: none;
  position: relative;
  background: transparent;
  cursor: pointer;
  width: 20%;
  svg{
    width: 100%;
  }
}
.cascading {
  position: absolute;
  right: 4%;
  top: 4%;

  border: none;
  border-radius: 5px;
  color: white;
  background: #FF2E2F;
  cursor: pointer;
  display: flex;
  width: 20px;
  height: 20px;
  padding: 3px 5px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;

  font-family: Rubik;
  font-size: 13px;
  font-weight: 400;
  line-height: 13px;
  letter-spacing: -0.03em;
  text-align: center;
}
.c-card__title-lock{
  max-width: 23px;
}
</style>
